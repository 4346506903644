import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ISeo, IActiveProduct } from 'src/app/shared/models';
import { IProduct, IProductDetail, IReviewsData, IUpgradeProduct } from 'src/app/shared/models/product-detail-payload.interface';
import { ApiService, UtilsService, MatDialogUtilsService } from 'src/app/shared/services';
import { EnvService } from 'src/app/shared/services/env.service';
import { PixelService } from 'src/app/shared/services/facebook-pixel/pixel.service';

@Component({
  selector: 'app-product-all-details',
  templateUrl: './product-all-details.component.html',
  styleUrls: ['./product-all-details.component.less']
})
export class ProductAllDetailsComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() paramSku: string = ''
  @Output() close = new EventEmitter();
  @Input() isHandset: boolean;


  @ViewChild("topContainer", { static: false }) topContainer: ElementRef;
  @ViewChild("gallery", { static: false }) galleryContainer: ElementRef;
  @ViewChild("itemTemplate", { static: true }) itemTemplate: TemplateRef<any>;

  product: IProduct;
  seoData: ISeo;
  productSubscription: Subscription;
  eventSubscription: Subscription;
  routeSubscription: Subscription;
  Math: Math = Math;
  selectedIndex: number;
  descriptionExist: boolean;
  isSwatchExist: boolean;
  isVariationExist: boolean;
  isProductFetching = true;
  showDetails = false;
  spinner = "assets/image/spinner.gif";
  description: any;
  productPrice: any;
  productWasPrice: any;
  variations = [];
  topHeight = { "max-height": "0" };
  swatches = [];
  errorMessage = "";

  activeTab = "mr";
  priceData = {
    price: "",
    wasPrice: "",
  };
  selectedSwatch = {
    swatch_image: null,
    price: "",
    wasPrice: "",
  };
  hasValidWasPrice = true;
  quantity = 1;
  quantityArray = [];
  isSetItemInInventory = false;
  localStorageUser = {};
  activeProduct: IActiveProduct;
  hasSelection: boolean;
  beforeSelection: boolean;
  checkSelection: boolean;
  schema = {};
  invalidLinkImageSrc = "assets/image/invalid_link.png";
  invalidLink: boolean;
  starIcons = [];
  recentProducts: any[] = [];
  recentReviews: IReviewsData;

  upgradeProducts: IUpgradeProduct[];
  otherPeopleProducts = [];

  mainProductImage = [];
  serverProductImagesWithIndex = [];

  showSku = false;
  productSku = "";
  pKey: string;
  updatedPrice: any;
  slideIndex = 0;
  newVariationFound: boolean;
  newVariationData: any;
  dupProduct: IProduct;
  prodBoardList: any[] = [];
  perRowCount: number;
  isPriceUpdate: boolean;
  brandCoverImg: any;
  newSku: string = "";
  salePriceStrike: number;
  onlySkuDetails: boolean;
  appUrl = '';
  nos: number;
  assetBaseUrl: string;
  desktopImageUrl: string;
  mobileImageUrl: string;
  showBanner: boolean = false;
  brandUrl: any;
  showViewOnLoader = true;
  isVariationCallLoading = false;
  showLoader = true;
  @Input() roomId: string;
  @Input() page: string;


  @Input() projectOptions: any[] = []
  selectedBookingId = '';
  isSingleDimension: boolean;
  dimensionExist: boolean;
  
  productImages: any[];
  features: string;
  height: number;
  width: number;

  constructor(
    private apiService: ApiService,
    public utils: UtilsService,
    public snackbar: MatSnackBar,

    private matDialogUtils: MatDialogUtilsService,
    private pixelService: PixelService,
    private activeRoute: ActivatedRoute,
    public env: EnvService,
  ) {
    this.appUrl = this.env.appUrl;
    this.utils.showCatNav = false;
  }
  getmsg(d) {
    return this.utils.compileMarkdownText(d)
  }
  ngOnInit(): void {
    this.onResize();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.paramSku && changes.paramSku.currentValue) {
      this.loadProduct();

    }
  }
get scrollHeight(){
  return document.getElementById('rightPanel').clientHeight + 100;


}

  get windowHeight() {
    return window.innerHeight;
  }
  ngAfterViewInit(): void {
    this.hideIntercomIcon()
  }
  hideIntercomIcon() {
    const interval = setInterval(() => {
      const intercom = document.getElementsByClassName(
        "intercom-lightweight-app"
      )[0];
      if (intercom) {
        intercom.classList.add("dn");
        clearInterval(interval);
      }
    }, 500);
    const intervalx = setInterval(() => {
      const intercomx = document.getElementsByClassName("intercom-app")[0];
      if (intercomx) {
        intercomx.classList.add("dn");
        clearInterval(intervalx);
      }
    }, 500);
  }

  loadProduct() {
    this.showLoader = true;
    this.quantity = 1;
    this.selectedBookingId = '';
    this.productSubscription = this.apiService
      .getProduct(this.paramSku)
      .subscribe(
        (payload: IProductDetail) => {
          if (payload.product) {
            this.processProduct(payload);

            this.isSingleDimension = payload.product.dimension.length === 1;
            this.dimensionExist = payload.product.dimension.length > 0;

          }
        },
        (error) => {
          this.invalidLink = true;
          this.isProductFetching = false;
        }
      );

    this.isVariationCallLoading = true;
    this.apiService
      .getProductSelection(this.paramSku)
      .subscribe((data: any) => {
        this.onlySkuDetails = false;
        if (data && data.selection && data.selection.length > 0) {
          this.newVariationFound = true;
          this.newVariationData = data;
          if (this.activeProduct) {
            this.activeProduct.in_inventory = true;
            if (!this.activeProduct.inventory_product_details.count) {
              this.activeProduct.inventory_product_details.count = 1;
            }
          }

        } else {
          let activeRecords = data.Skudetails?.find(f => f.is_active === '1');
          if (activeRecords) {
            this.onlySkuDetails = true;
            this.newVariationFound = true;
            this.newVariationData = data;
            this.newSku = activeRecords.sku;
          } else {
            this.newVariationFound = false;
          }
        }
        this.isVariationCallLoading = false;
      }, err => {
        this.isVariationCallLoading = false;
      });
  }

  private processProduct(payload: IProductDetail) {
    this.product = payload.product;
    this.features = this.utils.compileMarkdown(this.product.features);
    
    this.dupProduct = JSON.parse(JSON.stringify(payload.product));
    this.productImages = []; 
    this.productImages.push(payload.product.main_image);
    this.product.product_images_gallery.forEach(f=>{
    this.productImages.push(f);

    })
    setTimeout(() => {
      this.currentSlide(1);
    }, 0);
    if (
      this.product.variations.length === 0 &&
      this.product.product_status === "active"
    ) {
      this.isSetItemInInventory = true;
    }
    this.seoData = payload.seo_data;
    this.pixelService.trackViewContent(this.product);
    if (this.product.sku !== this.paramSku) {
      // this.router.navigateByUrl("/product/" + this.product.sku);
    }
    this.upgradeProducts = payload.upgrades || [];
    if (payload.product) {
      this.setProduct(payload);
    } else {
      this.invalidLink = true;
    }
    this.isProductFetching = false;
    if (
      Math.floor(this.product.min_price) ==
      Math.floor(this.product.max_price) ||
      Math.floor(this.product.min_price) >=
      Math.floor(this.product.min_was_price)
    ) {
      this.updatedPrice = JSON.parse(JSON.stringify(this.product.min_price));
    } else {
      this.updatedPrice = JSON.parse(JSON.stringify(this.product.max_price));
    }
    this.showLoader = false;
  }

  private setProduct(payload: IProductDetail) {
    this.updateActiveProduct(this.product);
    this.description = this.utils.compileMarkdown(
      this.product.description,
      true
    );
    this.descriptionExist = this.utils.checkDataLength(
      this.product.description
    );
    this.isSwatchExist = this.utils.checkDataLength(
      this.product.variations &&
      this.product.variations.filter(
        (variation) => variation.swatch_image !== null
      )
    );
    if (this.product.in_inventory) {
      this.productPrice = this.utils.formatPrice(
        this.product.inventory_product_details.price
      );
      this.productWasPrice = this.utils.formatPrice(
        this.product.inventory_product_details.was_price
      );
    } else {
      this.productPrice = this.product.min_price;

      this.productWasPrice = this.product.max_was_price;
    }
    if (typeof this.productPrice == "string") {
      const minPrice = Number(this.productPrice.split("-")[0]);
      const wasMinPrice = Number(this.productWasPrice.split("-")[0]);
      if (wasMinPrice <= minPrice) {
        this.hasValidWasPrice = false;
      }
    } else {
      const minPrice = Number(this.productPrice);
      const wasMinPrice = Number(this.productWasPrice);
      if (wasMinPrice <= minPrice) {
        this.hasValidWasPrice = false;
      }
    }

    this.isVariationExist = this.utils.checkDataLength(this.product.variations);

    if (!this.isVariationExist) {
      this.beforeSelection = true;
      this.checkSelection = true;
    }

    this.hasVariationsInventory();
    this.variations = this.product.variations.sort((a, b) =>
      a.name > b.name ? 1 : -1
    );
    if (this.product.set) {
      this.checkSetInventory(this.product.set);
    }
    if (!this.isVariationExist) {
      this.showSku = true;
      this.productSku = this.product.sku;
    }
    if (this.isVariationExist && this.product.variations.length === 1) {
      this.beforeSelection = true;
      this.checkSelection = true;
      this.showSku = true;
      this.productSku = this.activeProduct.sku;
    }
    if (this.newVariationFound && this.newSku) {
      this.productSku = this.newSku;
    } else {
      this.productSku = this.activeProduct.sku;

    }

    this.invalidLink = false;
  }

  setSkuOfCurrentSelectedProduct(event) {
    if (event.data.length === 1) {
      this.showSku = true;
      this.productSku = event.data[0].variation_sku;
    } else {
      this.showSku = false;
      this.productSku = event.current ? event.current.variation_sku : '';
    }
  }
  onDestroy(): void {
    this.productSubscription.unsubscribe();
    this.eventSubscription.unsubscribe();
  }


  checkSetInventory(product) {
    for (const item of product) {
      if (item.in_inventory) {
        this.isSetItemInInventory = true;
      }
    }
  }

  isArray(obj: any) {
    return Array.isArray(obj);
  }



  onSetImage(variation): void {
    this.mainProductImage = [];
    if (variation && variation.image) {
      const src = variation.image.split(",");
      let images = [];
      if (this.isArray(src)) {
        images = [...src];
      } else {
      }
      this.mainProductImage = [...images];
      this.updateActiveProduct(variation);
      this.hasSelection = true;
    } else {
      // if(variation && variation.inventory_product_details){
      this.activeProduct.inventory_product_details = variation.inventory_product_details;
      // this.product.inventory_product_details = this.activeProduct.inventory_product_details
      // }
      this.updateActiveProduct(this.product);
      this.mainProductImage[0] = this.product.main_image;
      this.hasVariationsInventory();
    }
  }

  get isUserLoggedIn(): boolean {
    if (JSON.parse(localStorage.getItem("user"))) {
      return (
        JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user")).email &&
        JSON.parse(localStorage.getItem("user")).email.length > 0
      );
    } else {
      return false;
    }
  }



  onSetPrice(priceData): void {
    if (!this.productPrice.includes("-")) {
      this.salePriceStrike = 0;
      this.productPrice = this.utils.formatPrice(
        priceData.price || this.product.min_price
      );
      this.productWasPrice = this.utils.formatPrice(
        priceData.min_was_price || this.product.max_was_price
      );
      this.isPriceUpdate = true;

      // this.showSku = true;
      this.updatedPrice = this.utils.formatPrice(
        priceData.price || this.product.min_price
      );
      if (priceData.price != priceData.wasPrice) {
        this.salePriceStrike = priceData.wasPrice;
      }
    }
    this.isSetItemInInventory = this.activeProduct.inventory_product_details.is_active == '1' ? true : false;
    this.activeProduct.in_inventory = this.activeProduct.inventory_product_details.is_active == '1' ? true : false;
    this.showSku = this.activeProduct.sku ? true : false;
  }


  openCartModal() {
    if (this.newVariationFound) {
      if (this.newVariationData.Skudetails.length > 0) {
        if (this.newSku) {
          this.saveCart();
        } else {
          this.hasSelection = false;
        }
      } else {
        if (
          this.newVariationData.selection.some((s) => s.requiredSelection == 0)
        ) {
          if (
            this.newSku.split("-").length - 1 ==
            this.newVariationData.selection.filter(
              (f) => f.requiredSelection !== 0
            ).length ||
            this.newSku.split("-").length - 1 ==
            this.newVariationData.selection.length
          ) {
            this.saveCart();
          } else {
            this.hasSelection = false;
          }
        } else {
          if (
            this.newSku.split("-").length - 1 !==
            this.newVariationData.selection.length
          ) {
            this.hasSelection = false;
          } else {
            this.saveCart();
          }
        }
      }
    } else {
      if (
        (!this.activeProduct.in_inventory &&
          !this.activeProduct.inventory_product_details.price) ||
        !this.beforeSelection
      ) {
        this.hasSelection = false;
      } else {
        if (this.activeProduct.in_inventory && this.activeProduct.inventory_product_details.price) {
          this.productPrice = this.activeProduct.inventory_product_details.price
        }
        this.saveCart();
      }
    }
  }

  saveCart() {
    this.hasSelection = true;
    // const data = {
    //   sku: this.activeProduct.sku,
    //   brand: this.product.brand_name,
    //   image: this.mainProductImage[0],
    //   name:
    //     this.activeProduct.sku === this.product.sku
    //       ? this.activeProduct.name
    //       : this.product.name + " " + this.activeProduct.name,
    //   price: this.productPrice,
    //   quantity: this.quantity,
    // };
    const postData = {
      product_sku:
        this.newVariationFound && this.newSku
          ? this.newSku : (!this.newVariationFound && this.showSku && this.productSku) ? this.productSku:
          (this.product.inventory_product_details && this.product.inventory_product_details.product_sku) ?
          this.product.inventory_product_details.product_sku
          : this.activeProduct.sku,
      parent_sku: (this.product.inventory_product_details && this.product.inventory_product_details.parent_sku) ?
        this.product.inventory_product_details.parent_sku
        : this.activeProduct.sku,
      count: this.quantity,
      room_id: this.page === 'client-shopping' ? this.roomId : this.selectedBookingId,
      is_virtual: this.newVariationFound && this.newSku ? 1 : 0,
    };

    if (this.page === 'client-shopping') {
      delete postData.room_id;
      const data = {
        sku: this.activeProduct.sku,
        brand: this.product.brand_name,
        image: this.product.main_image,
        name:
          this.activeProduct.sku === this.product.sku
            ? this.activeProduct.name
            : this.product.name + " " + this.activeProduct.name,
        price: this.productPrice,
        quantity: this.quantity,
      };

      this.apiService.addCartProduct(postData).subscribe(
        (payload: any) => {
          if (payload.status) {
            this.errorMessage = "";
            // this.snackbar.open('Added to Cart','x' , {duration:5000});
            this.matDialogUtils.openAddToCartDialog(data);
            // this.pixelService.trackAddToCart(data);
          } else {
            this.errorMessage = payload.msg;
          }
        },
        (error: any) => {
          this.errorMessage = "Cannot add this product at the moment.";
        }
      );
    } else {

      this.apiService.addDesignCartProduct(postData).subscribe((resp: any) => {
        if (resp.status) {
          this.snackbar.open("Product Added Successfully", "", { duration: 2000 });
          // this.dialogRef.close(true);
        } else {
          this.snackbar.open(resp.message, "x", { duration: 5000 });

        }
      },
        (error: any) => {
          this.errorMessage = "Cannot add this product at the moment.";
        });
    }



  }

  addUpgradeToCart(product: IUpgradeProduct) {
    const data = {
      sku: product.sku,
      brand: product.brand_name,
      image: product.image,
      name: product.name,
      price: product.price,
      quantity: 1,
    };
    const postData = {
      product_sku:
        (product.inventory_product_details && product.inventory_product_details.product_sku) ?
          product.inventory_product_details.product_sku
          : product.sku,
      parent_sku: (product.inventory_product_details && product.inventory_product_details.parent_sku) ?
        product.inventory_product_details.parent_sku
        : product.parent_sku,
      count: 1,
      isServiceRepOrder: 1,
      room_id: this.roomId,
      is_virtual: 0,
    };


    this.apiService.addCartProduct(postData).subscribe(
      (payload: any) => {
        if (payload.status) {
          this.errorMessage = "";
          this.snackbar.open('Added to Cart', 'x', { duration: 5000 });

          // this.matDialogUtils.openAddToCartDialog(data);
          this.pixelService.trackAddToCart(data);
        } else {
          this.errorMessage = payload.msg;
        }
      },
      (error: any) => {
        this.errorMessage = "Cannot add this product at the moment.";
      }
    );
  }
  updatePrice(e) {
    this.salePriceStrike = 0;
    this.updatedPrice = JSON.parse(JSON.stringify(this.product.min_price));
    let scenario1 =
      Math.floor(this.product.min_price) + Math.floor(e.sellingPrice);
    let scenario2 =
      Math.floor(this.product.min_was_price) + Math.floor(e.retailPrice);
    if (this.newVariationData.Skudetails.length > 0) {
      this.updatedPrice = +e.sellingPrice;
      if (Math.floor(e.retailPrice) != Math.floor(e.sellingPrice)) {
        this.salePriceStrike = Math.floor(e.retailPrice);
      }
    } else {
      this.updatedPrice = this.updatedPrice + +e.sellingPrice;
      if (scenario1 != scenario2) {
        this.salePriceStrike = scenario2;
      }
    }
    this.productPrice = this.utils.formatPrice(this.updatedPrice);

    this.isPriceUpdate = true;
  }
  getNewSku(event) {
    if (event === undefined) {
      this.isSetItemInInventory = false;
      this.activeProduct.in_inventory = false;
      this.product.in_inventory = false;
      return;
    }
    if (this.newVariationData.Skudetails.length > 0 && event) {

      this.hasSelection = true;
      this.newSku = event;
      this.productSku = event;
      this.activeProduct.inventory_product_details.is_drop =
        this.newVariationData.Skudetails.find((f) => f.sku == event).is_drop;
      this.productSku = event;
      this.activeProduct.inventory_product_details.is_bo_drop =
        this.newVariationData.Skudetails.find((f) => f.sku == event).is_bo_drop;
      if (this.newVariationData.Skudetails.length > 1) {
        this.mainProductImage[0] = this.newVariationData.Skudetails.find(
          (f) => f.sku == event
        ).imagePath
          ? this.newVariationData.Skudetails.find((f) => f.sku == event).imagePath
          : this.mainProductImage[0];
      }
      this.activeProduct.in_inventory = (this.newVariationData.Skudetails.find(
        (f) => f.sku == event
      ).is_active) === "1" ? true : false;
      this.product.in_inventory = (this.newVariationData.Skudetails.find(
        (f) => f.sku == event
      ).is_active) === "1" ? true : false;

      this.isSetItemInInventory = (this.newVariationData.Skudetails.find(
        (f) => f.sku == event
      ).is_active) === "1" ? true : false;
      if (this.activeProduct.inventory_product_details && this.activeProduct.inventory_product_details.count &&
        this.activeProduct.inventory_product_details.count > 1
      ) {

      } else {
        this.activeProduct.inventory_product_details.count = 1;
      }
    } else {
      this.hasSelection = true;
      this.isSetItemInInventory = this.activeProduct.inventory_product_details.is_active === "1" ? true : false;
      this.activeProduct.in_inventory = this.activeProduct.inventory_product_details.is_active === "1" ? true : false;
      this.product.in_inventory = this.activeProduct.inventory_product_details.is_active === "1" ? true : false;
      this.newSku = this.product.sku + "-" + event;
    }
    // this.activeProduct.inventory_product_details = 
  }


  updateActiveProduct(product) {
    if (

      this.product.variations.length === 1
    ) {
      this.activeProduct = {
        sku: product.variations[0].variation_sku,
        in_inventory: product.variations[0].in_inventory,
        name: product.variations[0].name,
        inventory_product_details:
          product.variations[0].inventory_product_details,
      };
      this.updatedPrice = Number(product.price);

    } else {
      this.activeProduct = {
        sku: product.variation_sku ? product.variation_sku : product.sku,
        in_inventory: product.in_inventory,
        name: product.name,
        inventory_product_details: (this.activeProduct && this.activeProduct.inventory_product_details) ? this.activeProduct.inventory_product_details : product.inventory_product_details
          ? product.inventory_product_details
          : [],
      };
      this.updatedPrice = Number(this.activeProduct.inventory_product_details.price);
    }
  }
  quantityLimit(count) {
    const maxNumber = count < 10 ? count : 10;
    return Array.from({ length: maxNumber }, Number.call, (i) => i + 1);
  }

  hasVariationsInventory() {
    if (
      this.isVariationExist &&
      this.product.inventory_product_details === null
    ) {
      if (this.product.variations.find((item) => item.in_inventory === true)) {
        this.activeProduct.in_inventory = true;
        if (!this.activeProduct.inventory_product_details.count) {
          this.activeProduct.inventory_product_details.count = 1;
        }
      }
    }
  }

  onSetSelectionChecked(e: boolean) {
    this.beforeSelection = e;
  }

  onClearSelection(e: boolean) {
    this.checkSelection = e;
  }

  onSetSelection(e: boolean) {
    this.hasSelection = e;
  }



  isDiscounted(product): boolean {
    product.is_price = product.price;
    const price = this.utils.getPriceObject(product);
    return price.isDiscounted;
  }

  @HostListener("window:resize")
  onResize() {
    this.height = window.innerHeight;
    this.width = window.innerWidth;
  }
  goToBrandUrl(p){
    if(p.product_url){
    if(p.product_url.includes('http')){
      window.open(p.product_url,'_blank');
    } else {
      window.open('https://'+ p.product_url,'_blank');
    }
  }
  }


  selectTab(tab) {
    this.activeTab = tab;
  }
  ngOnDestroy(): void {
  }
  showInterCom() {
    (<any>window).Intercom(
      "showNewMessage",
      "Hi there! I see you would like to chat with a design consultant."
    );
  }
  updateImg(ind) {
    if (ind) {
      if (!this.mainProductImage) {
        this.mainProductImage.push(
          `${this.product.product_images_gallery[ind]}`
        );
      } else {
        this.mainProductImage[0] = `${this.product.product_images_gallery[ind]
          }`;
      }
    } else {
      this.mainProductImage[0] = this.product.main_image;
    }
  }
  clearSelection() {
    this.isPriceUpdate = false;
    this.newSku = "";
  }
  closeProductNav() {
    this.close.emit();
  }
  get rightPanelHeight() {
    return document.getElementById('rightPanel').offsetHeight;
  }

get fullContentHeight(){
  if (!this.isHandset) { 
    const heighgt = window.innerHeight;
    if(heighgt>1150 && heighgt>  (document.getElementById('fullContent')?.clientHeight) +152){
      return heighgt +'px';
    } else{
    return  (document.getElementById('fullContent')?.clientHeight) +152 +'px';
    }
  } else {
    return this.windowHeight + 'px'
  }
}


get showShipping():boolean{
  if (this.showSku && this.utils.hasInventory(this.activeProduct) && this.activeProduct.inventory_product_details?.shipping_desc){
    return true;
  }
  if(this.activeProduct.inventory_product_details && this.activeProduct.inventory_product_details.is_drop){
    if(this.activeProduct.inventory_product_details.is_drop=='0'
      && (this.activeProduct.inventory_product_details.is_bo_lz || this.product.is_final==1 || this.product.is_mto==1)
    ){
      return true;

    } else if(this.activeProduct.inventory_product_details.is_drop=='1' 
      && (this.product.site === 'westelm' || this.product.site==='cb2' || this.product.site==='cab' ||
        this.activeProduct.inventory_product_details.is_mto=='1'
        || (this.activeProduct.inventory_product_details.is_mto=='0'&&this.activeProduct.inventory_product_details.is_bo_drop!==null)
      )
        ){
      return true;

        } else if(this.activeProduct.inventory_product_details.is_drop=='2'){
      return true;

        }
      } 
      return false;
}

  get pdContentHeight() {
    if (!this.isHandset) {
      let h =0;
      if(document.getElementById('pdContent')?.offsetHeight<400){
         h = (document.getElementById('pdContent')?.offsetHeight)*3;

      }  else if(document.getElementById('pdContent')?.offsetHeight>400 &&
      ( document.getElementById('pdContent')?.offsetHeight<600 &&this.windowHeight<800 )){
        h = (document.getElementById('pdContent')?.offsetHeight)*2;

     } else {
         h = (document.getElementById('pdContent')?.offsetHeight) * 3;
      }
      if (h > (this.windowHeight )) {
        return h + 'px';

      } else {
        return this.windowHeight + 'px';
      }
    } else {
      return this.windowHeight + 'px'
    }
  }
  get isDisabled() {
    const condition = this.selectedBookingId || this.page === 'client-shopping';
    if (condition) {
      if (this.isVariationExist &&
        this.product.product_status === 'active'
        && !this.product.redirect &&
        this.product.variations?.length > 0 && this.showSku && this.productSku && condition) {
        return false;
      } else if (this.product.product_status === 'active' && this.newVariationFound && this.newSku && condition) {
        return false;
      } else if (this.isVariationCallLoading) {
        return true;
      }
      if (!this.isVariationExist && condition) {
        return false;

      } else {
        return true;

      }
    }
    return true;
  }


  plusSlides(n: number) {
    this.showSlides((this.slideIndex += n));
  }
  currentSlide(n) {
    this.showSlides((this.slideIndex = n));
  }
  showSlides(n) {
    let i;
    let slides = document.getElementsByClassName(
      "pSlides"
    ) as HTMLCollectionOf<HTMLElement>;
    if (slides.length > 0) {
      let dots = document.getElementsByClassName("dot");
      if (n > slides.length) {
        this.slideIndex = 1;
      }
      if (n < 1) {
        this.slideIndex = slides.length;
      }
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active-dot", "");
      }
      slides[this.slideIndex - 1].style.display = "block";
      if (dots && dots[this.slideIndex - 1]) {
        dots[this.slideIndex - 1].className += " active-dot";
      }
    }
  }
}

