import {
  AfterViewInit,
  Component,
  DoCheck,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  ApiService,
  EventEmitterService,
  MatDialogUtilsService,
  UtilsService,
} from "src/app/shared/services";
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from "@angular/cdk/layout";
import { Observable, Subscription } from "rxjs";
import { EnvService } from "../../../shared/services/env.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Options } from "ng5-slider";
import {
  IFilterData,
  IProductPayload,
  IProductsPayload,
} from "src/app/shared/models";
import { HttpParams } from "@angular/common/http";
import { Location } from "@angular/common";
import { isArray } from "util";
import { first } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SCROLL_ICON_SHOW_DURATION } from "src/app/shared/constants";

@Component({
  selector: "app-product-all",
  templateUrl: "./product-all.component.html",
  styleUrls: ["./product-all.component.less"],
})
export class ProductAllComponent implements OnInit, DoCheck, AfterViewInit {
  interval: ReturnType<typeof setInterval> | undefined;
  isFetched = false;
  isUpdating = false;
  spinner = "assets/image/spinner.gif";

  productListings: any[] = [];

  page: number = 0;
  sorting: { sortBy: string; direction: "asc" | "desc" | '' } = {
    sortBy: "",
    direction: "",
  };
  sortBy: string = "";
  sortType: string = "";
  currentResponseLength: number = 0;

  bpObserver: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.Handset
  );
  bpSubscription: Subscription;
  isHandset = false;
  slideIndex = 0;

  filterData: any;
  sliderOptions: Options = {
    step: 1,
  };
  priceFilter = {};
  widthFilter = {};
  heightFilter = {};
  lengthFilter = {};
  depthFilter = {};
  diameterFilter = {};

  diameter_from: any;
  diameter_to: any;
  width_from: any;
  width_to: any;
  height_from: any;
  height_to: any;
  length_from: any;
  length_to: any;
  depth_from: any;
  depth_to: any;
  price_from: any;
  price_to: any;
  originalFilterData: any;
  showBottomPanel = true;
  filteredElements: string[] = [];
  defaultFilters = ["color", "shape", "style"];
  style: string = "";
  item: any;
  height: number;
  department_long: any;
  keyword: string;
  filterKeys: any;
  orderedFilterData: any[] = [];
  sizeBackup: any;
  iscurrentFilterApplied: boolean;
  filterURL: any;
  subCategories: any[] = [];
  width: number;
  urlFilter: any;
  products: IProductPayload[];
  productFilters: IFilterData;
  trend: string;
  category: string;
  totalCount = 0;
  filters = "";
  isProductFetching: boolean;
  total = 24;
  sortTypeList: any;
  pageNo: number;

  productsSubscription: Subscription;
  productFilter: any;
  showMobileFilter: boolean;
  showMobileSort: boolean;
  productsInRow: number = 2;
  color: any;
  shape: any;
  routeSubscription: Subscription;
  showLoader: boolean;
  storedFilterData: any;
  projectOptions: any[] = [];
  selectedDropdown = '';
  selectedProject = '';
  selectedDetails: any;
  innerWidth: number;
  assetBaseUrl: string;
  loadFilters: boolean;
  productsLoading: boolean;
  currentFilter: any;
  public Math: Math = Math;
  throttle = 700;
  scrollDistance = 1;
  scrollUpDistance = 2;
  gridView =true

  @ViewChild('mySidenav') mySidenav: ElementRef;
  filterErr: string;
  noProducts: boolean;
  
  private originalOffsetTop!: number;
  private isFixed = true;

  constructor(
    public api: ApiService,
    private breakpointObserver: BreakpointObserver,
    public env: EnvService,
    private router: Router,
    private location: Location,
    public utils: UtilsService,
    private activeRoute: ActivatedRoute,
    private eventEmitterService: EventEmitterService,
    private matDiaService: MatDialogUtilsService,
    private matSnackbar:MatSnackBar
  ) {
    document.addEventListener("click", this.findTopmostParent.bind(this));
    this.keyword = "all";
    this.bpSubscription = this.bpObserver.subscribe(
      (handset: BreakpointState) => {
        this.isHandset = handset.matches;
      }
    );
  }
  get pageWidth(){
   return document.body.clientWidth;
  }
  onAllScrollDown(){
    if(this.currentResponseLength===20)  this.loadMore()
  }

  ngAfterViewInit(){
    this.hideIntercomIcon()

    const filterBar = document.getElementById('filterBar');
    if (filterBar) {
      this.originalOffsetTop = filterBar.offsetTop;
      this.onWindowScroll()
    }
  }
  topPosToStartShowing = 300;
  fixFilterBar = 150;
  isIconShow = false;
  showBar = false;
  timeout: any;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const filterBar = document.getElementById('filterBar');
    if (!filterBar) return;

    const scrollPosition =   window.pageYOffset ||
    document.documentElement.scrollTop ||
    document.body.scrollTop ||
    0;

    if (scrollPosition >= this.originalOffsetTop && !this.isFixed) {
      filterBar.style.position = 'fixed';
      filterBar.style.top = '0';
      filterBar.style.width = (this.pageWidth-200)+'px';
      filterBar.style.background = '#fff';
      filterBar.style.zIndex = '1';
      filterBar.style.marginTop = '0px';

      filterBar.style.padding = '12px 0px';
      this.isFixed = true;
    } else if (scrollPosition < this.originalOffsetTop && this.isFixed) {
      filterBar.style.position = 'static';
      filterBar.style.marginTop = '0px';

      this.isFixed = false;
    }
 
  this.isIconShow = scrollPosition >= this.topPosToStartShowing;
  this.showBar = scrollPosition >= this.fixFilterBar;
  const self = this;
  if (this.isIconShow) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      self.isIconShow = false;
    }, SCROLL_ICON_SHOW_DURATION);
  }
}

gotoTop() {
  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
}


  findTopmostParent(element) {
    let parent = element.target.parentNode;
    let isOk;
    let firstNode = element.target.innerHTML.includes('SHOW')
    || (parent && (parent.className === "filter-sectionCheck" || parent.textContent===('Select All')
    ||parent.textContent.includes('SHOW')
    || parent.textContent.includes('Clear All') ))? true : false;
    while (parent && parent.parentNode) {
      if (parent.id === "mySidenav") {
        isOk = true;
        break;
      }
      parent = parent.parentNode;
    }
    if (!isOk && !firstNode) {
      this.closeNav();
    }
  }
  @HostListener("window:resize")
  onResize() {
    this.height = window.innerHeight;
    this.width = window.innerWidth;
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.assetBaseUrl = this.env.assetBaseUrl.substring(
      0,
      this.env.assetBaseUrl.length - 1
    );

    this.eventEmitterService.userChangeEvent
      .asObservable()
      .subscribe((user) => {
        if (user.id && user.email) {
        } else {
          this.productListings = [];
          // this.matDiaService.o
        }
      });
      this.getParamsFromQuery();

    this.getDesignprojectDropdown();
  }

  getDesignprojectDropdown() {
    this.api.getProjectDropdown().subscribe((res: any) => {
      this.projectOptions = res;
    });
  }

  removeProduct() {
    let obj = {
      room_id: this.selectedProject,
      product_id: this.productListings
        .filter((f) => f.checked)
        .map((m) => m.product_sku).join(','),
    };
    this.api.removeProduct(obj).subscribe((res) => {
      this.loadProjectBasedOnSelection();
    });
  }
  showAllPtojects() {
    this.resetFilter();
    this.selectedProject = '';
    this.selectedDetails = null;
    // this.getProductListing(0);
  }
  loadProjectBasedOnSelection(page = 0) {
    this.filteredElements = [];
    this.filterURL = '';
    this.subCategories = [];
    if(this.filterData) this.uncheckAllItems(this.filterData["class"]);

    let filterData = this.formFilterURL();
    this.productsLoading = true;

    this.filters = filterData;
    this.updateQueryString();

    this.isFetched = false;
    this.selectedDetails = this.projectOptions.find(f => f.room_id == this.selectedProject);
    this.selectedProject
    this.api
      .getProductsByRoomId(this.selectedProject, page)
      .subscribe((response: any) => {


        this.isFetched = true;
        response.forEach((product: any) => {
          product["checked"] = false;
          product["addCart"] = 'loading';
          product["allImages"] = [];
          if (product["main_product_images"]) {
            product["allImages"].push(
              product["main_product_images"].split(",")
            );
          }
          if (product["product_images_gallery"]) {
            product["allImages"].push(
              product["product_images_gallery"].split(",")
            );
          }
          product["allImages"] = product["allImages"].flat();
          product['main_image'] = this.assetBaseUrl+ product.allImages[0];
          product['name'] = product.product_name;
          product["product_feature"] = this.utils.compileMarkdown(
            product.feature
          );
        });
        this.currentResponseLength = response.length;

        if (page === 0) {
          this.productListings = [];
          this.productListings = response;
        } else {
          this.productListings = [...this.productListings, ...response];
        }
        this.isUpdating = false;
        this.productsLoading = false;
        this.getCartProductsFromDashboard();

        this.updateQueryString();
        this.closeNav();
        this.closeProductNav();

        setTimeout(() => {
          for (let i = 0; i < this.productListings.length; i++) {
            this.showSlides(0, i, true);
          }
        }, 0);
      });
  }

  getCartProductsFromDashboard() {
    this.api.getCartProductsFromDashboard(this.selectedProject).subscribe((resp: any) => {
      if (resp && resp.products && resp.products.length>0) {


        this.productListings.forEach(e => {

   
          resp.products.forEach(f => {
            if(f.parent_sku && f.parent_sku === e.parent_sku){
              e.addCart = 'addedToCart';
            } else if (f.product_sku === e.product_sku) {
              e.addCart = 'addedToCart';

            } else {
              e.addCart = e.addCart === 'addedToCart' ? 'addedToCart' : 'showCart';
            }
          })

        })
      } else {
        this.productListings.forEach(f => f.addCart = 'showCart');
      }
    })
  }

  getParamsFromQuery(): void {
    const routeConfig = this.activeRoute.routeConfig.path;

    this.routeSubscription = this.activeRoute.queryParams.subscribe(
      (params) => {
        this.selectedProject = params.room_id || '';
        this.filters = params.filters || "";
        this.urlFilter = JSON.parse(JSON.stringify(this.filters));
        this.filterURL = JSON.parse(JSON.stringify(this.filters));
        this.getFilteredEle(params.filters);

        this.pageNo = parseInt(params.pageNo) || 0;
        this.sortType = params.sortType || "";
        this.getFiltersFromQuery();
        if(this.selectedProject){
          this.loadProjectBasedOnSelection(this.pageNo)
        } else {
        this.getProductListing(this.pageNo, this.urlFilter);
        }

      }
    );
  } 
  ngDoCheck(): void {

    if (this.matDiaService.closeClicked) {
      this.matDiaService.closeClicked = false;
      // this.router.navigate(["/"]);
      // setTimeout(() => {

      // window.location.reload();
      // }, 100);
    }
  }

  getProductListing(page = 0, isFilter?) {
    this.isUpdating = true;
    if (isFilter) {
      this.filters = this.urlFilter;
    } else {
      let filterData = this.formFilterURL();
      this.filters = filterData;
    }
    this.productsLoading = true;
    this.noProducts = false

    this.api
      .getAllProductsListing(
        page,
        this.filters,
        this.sorting.sortBy,
        this.sorting.direction
      )
      .subscribe(
        (response: any) => {
          if (response.status === "Unauthorised") {
            this.isFetched = true;
            this.isUpdating = false;
            const width = this.isHandset ? "90%" : "35%";
            const data = this.matDiaService.openSigninDialog(width, true);
            data.afterClosed((res) => {
            });
            this.productsLoading = false;

          } else if(response.length===0){
            this.isFetched = true;
            this.isUpdating = false;
            this.noProducts = true;

          } else {
            response.forEach((product: any) => {
              product["checked"] = false;
              product["allImages"] = [];
              if (product["main_product_images"]) {
                product["allImages"].push(
                  product["main_product_images"].split(",")
                );
              }
              if (product["product_images_gallery"]) {
                product["allImages"].push(
                  product["product_images_gallery"].split(",")
                );
              }
              product["allImages"] = product["allImages"].flat();
              product["product_feature"] = this.utils.compileMarkdown(
                product.feature
              );

          product['main_image'] = this.assetBaseUrl+ product.allImages[0];
          product['name'] = product.product_name;
            });
            this.productsLoading = false;

            if (page === 0) {
              this.productListings = [];
              this.productListings = [...this.productListings, ...response];
            } else {
              this.productListings = [...this.productListings, ...response];
            }
            this.isFetched = true;
            this.isUpdating = false;
            this.currentResponseLength = response.length;
            this.updateQueryString();
            // this.closeNav();
            setTimeout(() => {
              for (let i = 0; i < this.productListings.length; i++) {
                this.showSlides(0, i, true);
              }
            }, 0);
          }
        },
        (err) => {
          this.productsLoading = false;

          if (err.error.status === "Unauthorised") {
            this.isFetched = true;
            this.isUpdating = false;
            const width = this.isHandset ? "90%" : "35%";
            const data = this.matDiaService.openSigninDialog(width, true);
            data.afterClosed((res) => {
            });
            this.closeNav();
          }
        }
      );
  }

  loadMore() {
    this.page++;
    if (this.selectedProject) {
      this.loadProjectBasedOnSelection(this.page);
    } else {
      this.getProductListing(this.page);
    }
  }

  clearFilters() {
    this.filters = "";
    this.getProductListing(0);
  }
  setSorting(newSortBy: string) {
    if (this.sorting.sortBy === newSortBy) {
      this.sorting.direction =
        this.sorting.direction === "asc" ? "desc" : "asc";
    } else {
      this.sorting.sortBy = newSortBy;
      this.sorting.direction = "asc";
    }
    this.getProductListing();
  }

  toggleSort(newSortType: string) {
    this.sortType = newSortType;
    this.getProductListing();
  }

  openImageInNewTab(imageUrl: string) {
    window.open(imageUrl, "_blank");
  }

  openProductInNewTab(productUrl: string) {
    this.router.navigate([]).then(() => {
      window.open(`product/${productUrl}`, "_blank");
    });
  }
  plusSlides(n: number, row) {
    this.showSlides((this.slideIndex += n), row);
  }

  showSlides(n, row, init?) {
    let i;
    let slides = document.getElementsByClassName(
      "mySlides" + row
    ) as HTMLCollectionOf<HTMLElement>;
    if (!init) {
      if (n > slides.length) {
        this.slideIndex = 1;
      }
      if (n < 1) {
        this.slideIndex = slides.length;
      }
    } else {
      this.slideIndex = 1;
    }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    if (slides[this.slideIndex - 1]) {
      slides[this.slideIndex - 1].style.display = "block";
    }
  }

  getFilteredEle(data) {
    if (data) {
      this.filteredElements = [];
      let matches = data.match(/;([^:]+):/g);
      let extractedCharacters = [];
      if (data.split(":")[0]) {
        extractedCharacters.push(data.split(":")[0]);
      }

      if (matches) {
        for (let match of matches) {
          let extracted = match.substring(1, match.length - 1);
          // Removing the ';' and ':'
          if (extracted !== "category") {
            extractedCharacters.push(extracted);
          }
        }
      }
      this.filteredElements = extractedCharacters;
    }
  }

  uncheckAllItems(data: any[]) {
    data.forEach((category: any) => {
      Object.keys(category).forEach((key: string) => {
        if (Array.isArray(category[key])) {
          category[key].forEach((item: any) => {
            item.checked = false;

            Object.keys(item).forEach((it: string) => {
              if (Array.isArray(item[it])) {
                item[it].forEach((em: any) => {
                  em.checked = false;
                });
              }
            });
          });
        }
      });
    });
  }

  getFilterAndLoadProducts() {
    // this.subCategories[i].checked = !this.subCategories[i].checked;
    this.loadFilters = true;
    this.filters = this.formFilterURL();
    this.api
      .getFilteredData(this.keyword + "", this.filters, this.sortType, "")
      .subscribe(
        (payload: any) => {
          this.productFilters = payload.filterData;
          this.filterKeys = payload.filterKeys;
          this.sortTypeList = payload.sortType;
          if (payload.total) {
            this.totalCount = payload.total;
          }
          this.getFiltersData();
          this.filters = "";
          this.getProductListing();
          this.loadFilters = false;
        },
        (err) => {
          this.isProductFetching = false;
          this.loadFilters = false;

        }
      );
    this.loadProducts();
  }

  getFilterData(onlyClass = false) {
    // let sn = document.getElementsByClassName('sidenav') as any;
    // if(sn) sn[0].style.overflow='hidden';
    this.loadFilters = true;

    let filterData = this.formFilterURL();
    this.filters = filterData;
    this.storedFilterData = JSON.parse(JSON.stringify(this.orderedFilterData));
    this.api
      .getFilteredData(this.keyword, this.filters, this.sortType, "")
      .subscribe(
        (payload: any) => {
          this.productFilters = payload.filterData;
          if (this.filterURL) {
            const inputString = JSON.parse(JSON.stringify(this.filterURL));
            const pairedData = this.api.pairKeyValues(inputString);
            pairedData.forEach((f) => {
              if (
                this.productFilters[f.key] &&
                f.key !== "size" &&
                f.key !== "price"
              ) {
                f.value.forEach((v) => {
                  this.productFilters[f.key].forEach((element) => {
                    if (element.value == v) {
                      element.checked = true;
                    }
                  });
                });
              }
            });
          }
          this.filterKeys = payload.filterKeys;
          this.sortTypeList = payload.sortType;
          if (payload.total) {
            this.totalCount = payload.total;
          }
          this.getFiltersData();
            this.getAvailableSubCategories();
          this.showLoader = false;
          this.filters = "";
          const currentScroll = this.mySidenav.nativeElement.scrollTop;

          setTimeout(() => {
            this.mySidenav.nativeElement.scrollTop = currentScroll;
          }, 0);
        },
        (err) => {
          this.isProductFetching = false;
          this.loadFilters = false;

        }
      );
    // }
  }
  getFiltersFromQuery() {

    this.loadFilters = true;

    let filterData = this.formFilterURL();

    if (this.urlFilter) {
      this.filters = this.urlFilter;
    } else {
      this.filters = filterData;
    }
    // }
    this.showLoader = true;
    this.storedFilterData = JSON.parse(JSON.stringify(this.orderedFilterData));
    this.api
      .getFilteredData(this.keyword, this.filters, this.sortType, "")
      .subscribe(
        (payload: any) => {
          this.productFilters = payload.filterData;
          if (this.filterURL) {
            const inputString = JSON.parse(JSON.stringify(this.filterURL));
            const pairedData = this.api.pairKeyValues(inputString);
            pairedData.forEach((f) => {
              if (
                this.productFilters[f.key] &&
                f.key !== "size" &&
                f.key !== "price"
              ) {
                f.value.forEach((v) => {
                  this.productFilters[f.key].forEach((element) => {
                    if (element.value == v) {
                      element.checked = true;
                    }
                  });
                });
              }
            });
          }
          this.filterKeys = payload.filterKeys;
          this.sortTypeList = payload.sortType;
          if (payload.total) {
            this.totalCount = payload.total;
          }
          this.getFiltersData();

        
            this.getAvailableSubCategories();
        
          this.showLoader = false;
          this.filters = "";

          this.loadFilters = false;

        },
        (err) => {
          // alert('arumugam')
          this.filterErr = 'Filter data failed to load. Please try again or contact support for further asssitance.'
          this.isProductFetching = false;
          this.loadFilters = false;

        }
      );
  }

  loadProducts(isFilter?): void {
    this.pageNo = 0;
    this.isProductFetching = true;

    if (isFilter) {
      this.filters = this.urlFilter;
    } else {
      let filterData = this.formFilterURL();
      this.filters = filterData;
    }

    this.filterURL = JSON.parse(JSON.stringify(this.filters));
    this.getProductListing();
    // this.productsSubscription = this.api
    //   .getAllProductsUsingKeyword(
    //     this.keyword,
    //     this.total,
    //     this.filters,
    //     this.sortType,
    //     0
    //   )
    //   .pipe(first())
    //   .subscribe(
    //     (payload: IProductsPayload) => {
    //       this.products = payload.products;
    //       // this.productFilters = payload.filterData;
    //       // this.sortTypeList = payload.sortType;
    //       this.totalCount = payload.total;
    //       this.updateQueryString();
    //       this.isProductFetching = false;
    //     },
    //     (err) => {
    //       this.products = [];
    //       this.isProductFetching = false;
    //     }
    //   );
  }

  updateQueryString(): void {
    let params = new HttpParams();
    // if (this.trend) {
    //   params = params.set(this.trend, 'true');
    // }
    if(this.selectedProject){
      params = params.set("room_id", this.selectedProject);
    }
    if (this.filterURL) {
      params = params.set("filters", this.filterURL);
    }
    if (this.sortType) {
      params = params.set("sortType", this.sortType);
    }
    if (this.pageNo) {
      params = params.set("pageNo", this.pageNo.toString());
    }
    // setTimeout(() => {
    this.location.replaceState(
      window.location.pathname,
      params.toString(),
      this.location.getState()
    );
    // }, 0);
  }

  setPrdFilters(e) {
    this.productFilter = e;
  }
  onSetFilters(e): void {
    if (e || e === "") {
      this.filters = e;
      if (this.filters) {
        this.filters = this.filters.replace(/category/g, "class");
      }
      this.getFilteredEle(e);
      if (e.includes("class")) {
        this.getFilterData(true);
      }
      this.applyFilter(this.filters, false);
    }
  }

  onSetSortType(e): void {
    this.sortType = e;
    this.loadProducts();
    this.getFilterData();
  }

  onSetMobileToggle($e): void {
    this.toggleMobileFilter();
  }

  onSetSortToggle($e): void {
    this.toggleMobileSort();
  }

  toggleMobileFilter() {
    this.showMobileFilter = !this.showMobileFilter;
    if (this.showMobileFilter) {
      this.hideIntercomIcon();
    } else {
      // this.showIntercom();
    }
  }

  hideIntercomIcon() {
    const interval = setInterval(() => {
      const intercom = document.getElementsByClassName(
        "intercom-lightweight-app"
      )[0];
      if (intercom) {
        intercom.classList.add("dn");
        clearInterval(interval);
      }
    }, 500);
    const intervalx = setInterval(() => {
      const intercomx = document.getElementsByClassName("intercom-app")[0];
      if (intercomx) {
        intercomx.classList.add("dn");
        clearInterval(intervalx);
      }
    }, 500);
  }

  showIntercom() {
    const intercom = document.getElementsByClassName(
      "intercom-lightweight-app"
    )[0];
    if (intercom && intercom.classList) {
      intercom.classList.remove("dn");
    }
  }

  toggleMobileSort() {
    this.showMobileSort = !this.showMobileSort;
  }

  toggleMobileView() {
    if (this.productsInRow === 3) {
      this.productsInRow = 1;
    } else {
      this.productsInRow += 1;
    }
  }

  assignSliderData(event, name) {
    this.currentFilter = name;
    if (name == "diameter") {
      this.diameter_from = event.minValue;
      this.diameter_to = event.maxValue;
    } else if (name == "width") {
      this.width_from = event.minValue;
      this.width_to = event.maxValue;
    } else if (name == "height") {
      this.height_from = event.minValue;
      this.height_to = event.maxValue;
    } else if (name == "length") {
      this.length_from = event.minValue;
      this.length_to = event.maxValue;
    } else if (name == "depth") {
      this.depth_from = event.minValue;
      this.depth_to = event.maxValue;
    } else if (name == "price") {
      this.price_from = event.minValue;
      this.price_to = event.maxValue;
    }

  }

  submitSlider(event, name) {
    this.assignSliderData(event, name)
    if (!this.filteredElements.includes(name)) {
      this.filteredElements.push(name);
    }
    this.iscurrentFilterApplied = true;
    this.getFilterData(false);

    this.applyFilter(false, false);
  }

  minValue: number = 50;
  maxValue: number = 200;
  options: Options = {
    floor: 0,
    ceil: 250
  };

  resetFilter() {
    this.filterData = JSON.parse(JSON.stringify(this.originalFilterData));

    if (this.filterData['brand']) this.filterData['brand'].forEach(f => f.checked = false);
    if (this.filterData['class']) this.filterData['class'].forEach(f => f.checked = false);
    if (this.filterData['color']) this.filterData['color'].forEach(f => f.checked = false);
    if (this.filterData['subcategory']) this.filterData['subcategory'].forEach(f => f.checked = false);
    if (this.filterData['country']) this.filterData['country'].forEach(f => f.checked = false);
    if (this.filterData['designer']) this.filterData['designer'].forEach(f => f.checked = false);
    if (this.filterData['style']) this.filterData['style'].forEach(f => f.checked = false);
    if (this.filterData['seating']) this.filterData['seating'].forEach(f => f.checked = false);
    if (this.filterData['shape']) this.filterData['shape'].forEach(f => f.checked = false);



    this.filterData["class"] = JSON.parse(
      JSON.stringify(this.originalFilterData["class"])
    );
    // this.subCategories = [];
    this.orderedFilterData.forEach((f) => {
      f.showAll=false;
      if (f.value && isArray(f.value) && f.value.length > 0) {
        f.value.forEach((element) => {
          if (element.checked) {
            element.checked = false;
        

          }
        });
      }
    });

    this.iscurrentFilterApplied = false;

    this.sizeBackup = JSON.parse(
      JSON.stringify(this.originalFilterData["size"])
    );
    this.widthFilter = this.sizeBackup["width"];
    this.heightFilter = this.sizeBackup["height"];
    this.lengthFilter = this.sizeBackup["length"];
    this.depthFilter = this.sizeBackup["depth"];
    // this.productFilters["size"] = [{ name: "size" }];
    this.priceFilter = this.productFilters["price"];
    this.priceFilter["name"] = "price";
    this.diameter_from = null;
    this.diameter_to = null;
    this.width_from = null;
    this.width_to = null;
    this.height_from = null;
    this.height_to = null;
    this.length_from = null;
    this.length_to = null;
    this.depth_from = null;
    this.depth_to = null;
    this.price_from = null;
    this.price_to = null;
    this.filteredElements = [];
    this.filterURL = '';
    this.subCategories = [];
    this.filterErr = '';
    this.noProducts = false;
    this.uncheckAllItems(this.filterData["class"]);

    let filterData = this.formFilterURL();
    this.productsLoading = true;

    this.filters = filterData;
    this.getFilterData(false);
    this.page = 0;
    this.api
      .getAllProductsListing(
        this.page,
        "",
        this.sorting.sortBy,
        this.sorting.direction
      )
      .subscribe((response: any) => {
        response.forEach((product: any) => {
          product["checked"] = false;
          product["allImages"] = [];
          if (product["main_product_images"]) {
            product["allImages"].push(
              product["main_product_images"].split(",")
            );
          }
          if (product["product_images_gallery"]) {
            product["allImages"].push(
              product["product_images_gallery"].split(",")
            );
          }
          product["allImages"] = product["allImages"].flat();
          product['main_image'] = this.assetBaseUrl+ product.allImages[0];
          product['name'] = product.product_name;
          product["product_feature"] = this.utils.compileMarkdown(
            product.feature
          );
        });
        this.productsLoading = false;

        if (this.page === 0) {
          this.productListings = [];
          this.productListings = [...this.productListings, ...response];
        } else {
          this.productListings = [...this.productListings, ...response];
        }
        this.isFetched = true;
        this.isUpdating = false;
        this.currentResponseLength = response.length;
        this.filterURL = "";
        this.updateQueryString();
        // this.closeNav();
        setTimeout(() => {
          for (let i = 0; i < this.productListings.length; i++) {
            this.showSlides(0, i, true);
          }
        }, 0);
      }, err => {
        this.productsLoading = false;

      });
  }
  goTableView(){
    this.gridView=false
    setTimeout(() => {
      for (let i = 0; i < this.productListings.length; i++) {
        this.showSlides(0, i, true);
      }
    }, 0);
  }
  formFilterURL(): string {
    let filterUrl = "";
    if (this.productFilters) {
      this.filteredElements.forEach((f) => {
        if (
          !f.includes("price") &&
          f!==("size") &&

          !f.includes("diameter") &&
          !f.includes("width") &&
          !f.includes("height") &&
          !f.includes("length") &&
          !f.includes("depth") &&
          f != "class" && f != "subcategory" && f != "category"
        ) {
          let checked = [];
          if (this.filterData[f]) {
            const getEle = this.orderedFilterData.find(s => s.key == f)?.value;
            getEle?.forEach((element) => {
              if (element.checked) {
                checked.push(element.value);
              }
            });

            filterUrl =
              filterUrl +
              (checked.length > 0 ? `${f}:${checked.join(",")};` : "");
          }
        } else if (f == "class") {
          if (this.productFilters[f]) {
            let catchecked = [];
            const getEle = this.orderedFilterData.find(s => s.key == f).value;
            getEle.forEach((element) => {
              if (element.checked) {
                catchecked.push(element.value);
              }
            });
            filterUrl = filterUrl + `${"class"}:${catchecked.join(",")};`;
          }
        } else if (f == "price") {
          filterUrl =
            filterUrl +
            `${"price_from"}:${this.price_from};${"price_to"}:${this.price_to
            };`;
        } else if (f == "diameter") {
          filterUrl =
            filterUrl +
            `${"diameter_from"}:${this.diameter_from};${"diameter_to"}:${this.diameter_to
            };`;
        } else if (f == "width") {
          filterUrl =
            filterUrl +
            `${"width_from"}:${this.width_from};${"width_to"}:${this.width_to
            };`;
        } else if (f == "height") {
          filterUrl =
            filterUrl +
            `${"height_from"}:${this.height_from};${"height_to"}:${this.height_to
            };`;
        } else if (f == "length") {
          filterUrl =
            filterUrl +
            `${"length_from"}:${this.length_from};${"length_to"}:${this.length_to
            };`;
        } else if (f == "depth") {
          filterUrl =
            filterUrl +
            `${"depth_from"}:${this.depth_from};${"depth_to"}:${this.depth_to
            };`;
        }
      });
    }
    if (this.subCategories && this.subCategories.some((s) => s.checked)) {
      let category = this.subCategories
        .filter((s) => s.checked)
        .map((s) => s.category_id);
      filterUrl = filterUrl + `category:${category.join(",")};`;
    }
    return filterUrl;
  }
  applyFilter(filterFromMobile, onlyCls) {
    let filterData = this.formFilterURL();

    this.filters = filterFromMobile ? filterFromMobile : filterData;

    if (this.filters) {
      this.filterURL = JSON.parse(JSON.stringify(this.filters));
    }
    this.updateQueryString();
    this.iscurrentFilterApplied = false;
    this.productsLoading = true;
    this.api.getAllProductsListing(
      this.page,
      this.filters,
      this.sorting.sortBy,
      this.sorting.direction
    ).subscribe((response: any) => {
      if(response.length===0){
        this.isFetched = true;
        this.isUpdating = false;
        this.noProducts = true;

      }  
      response.forEach((product: any) => {
       
        product["checked"] = false;
        product["allImages"] = [];
        if (product["main_product_images"]) {
          product["allImages"].push(
            product["main_product_images"].split(",")
          );
        }
        if (product["product_images_gallery"]) {
          product["allImages"].push(
            product["product_images_gallery"].split(",")
          );
        }
        product["allImages"] = product["allImages"].flat();

        product['main_image'] = this.assetBaseUrl+ product.allImages[0];
        product['name'] = product.product_name;
        // product["product_feature"] = this.utils.compileMarkdown(
        //   product.feature
        // );
      });
      this.productsLoading = false;

      if (this.page === 0) {
        this.productListings = [];
        this.productListings = [...this.productListings, ...response];
      } else {
        this.productListings = [...this.productListings, ...response];
      }
      this.isFetched = true;
      this.isUpdating = false;
      this.updateQueryString();
      // if (!onlyCls) {
      //   this.closeNav();
      // }
      this.currentResponseLength = response.length;
      setTimeout(() => {
        for (let i = 0; i < this.productListings.length; i++) {
          this.showSlides(0, i, true);
        }
      }, 0);
    }, err => {
      this.productsLoading = false;

    });

    // this.api
    //   .getProduct(data, filterData, this.pageNo)
    //   .subscribe((response: any) => {
    //     // this.productList =  response.products;
    //     // if (this.productList.length === 0) {
    //     //   this.productList = [...response.products];
    //     // } else {
    //     //   this.productList = [...this.productList, ...response.products];
    //     // }
    //   });
  }

  getAvailableSubCategories() {
    let filterData = this.formFilterURL();
    this.filters = filterData;
    if (this.filters) {
      this.filterURL = JSON.parse(JSON.stringify(this.filters));
    }
    const category = this.filterData["class"]
      .filter((f) => f.checked)
      .map((f) => f.value);
    if (category.length > 0) {
      this.subCategories = [];
      this.filterData["class"].forEach((c) => {
        category.forEach((cat) => {
          if (c.value === cat) {
            c.department.forEach((d) => {
              let data = d.categories.filter((f) => f.enabled === true);

              if (this.subCategories.length > 0) {
                this.subCategories = [
                  ...new Set([...this.subCategories, ...data]),
                ];
              } else {
                this.subCategories = data;
              }
            });
          }
        });
      });
      this.subCategories = this.subCategories.filter(
        (item, index) => this.subCategories.indexOf(item) === index
      );
    } else {
      this.subCategories = [];
      this.filterData["class"].forEach((c) => {
        c.department.forEach((d) => {
          let data = d.categories.filter((f) => f.enabled === true);

          if (this.subCategories.length > 0) {
            this.subCategories = [
              ...new Set([...this.subCategories, ...data]),
            ];
          } else {
            this.subCategories = data;
          }
        });
      });
      this.subCategories = this.subCategories.filter(
        (item, index) => this.subCategories.indexOf(item) === index
      );
    }
    this.productFilters['subcategory'] = this.subCategories;
    if (this.orderedFilterData.find(f => f.key === 'class')) {
      let ind = this.orderedFilterData.findIndex(f => f.key === 'subcategory');
      if (ind > -1) {
        this.orderedFilterData[ind].value = this.subCategories;
      } else {
        let ind = this.orderedFilterData.findIndex(f => f.key === 'class');
        this.orderedFilterData.splice(ind + 1, 0, {
          key: 'subcategory',
          value: this.subCategories,
          opened: false,
          showAll:false,
        })

      }
    }
  }
  selectFilter(d, name, event = null) {

    // let sn = document.getElementsByClassName('sidenav') as any;
    // if(sn) sn[0].style.overflow='hidden'; 
      if (event) event.preventDefault();
      this.currentFilter = name;
      if (name !== 'color') d.checked = !d.checked;


      this.productFilters[name].find((f) => f.name === d.name).checked =
        d.checked;

      if (this.productFilters[name].filter((f) => f.checked).length > 0) {
        if (!this.filteredElements.includes(name)) {
          this.filteredElements.push(name);
        }
      } else {
        this.filteredElements = this.filteredElements.filter((f) => f != name);
      }
      // if(name==='subcategory'){
      //   // let ind = this.productFilters[name].findIndex((f) => f.name === d.name);
      //   this.getFilterAndLoadProducts()
      // } else {
      this.page = 0;
      if (name === "class" || name === 'subcategory') {

        this.getFilterData(true);
        if (name === "class") this.getAvailableSubCategories();
        this.applyFilter("", true);
      } else {
        this.getFilterData(false);
        this.applyFilter("", false);

      }
      let filterData = this.formFilterURL();

      this.filters = filterData;

      if (this.filters) {
        this.filterURL = JSON.parse(JSON.stringify(this.filters));
      }
      this.updateQueryString();
      // }
      this.iscurrentFilterApplied = true; 
  }
  selectAll(name) {
    this.currentFilter = name;
    this.productFilters[name].forEach((element) => {
      element.checked = true;
    });
    if (!this.filteredElements.includes(name)) {
      this.filteredElements.push(name);
    }

    const ind = this.orderedFilterData.findIndex(f => f.key === name);
    this.orderedFilterData[ind].value.forEach(f => f.checked = true);
    this.page = 0;

    if (name === "class") {
      this.getFilterData(true);
      this.getAvailableSubCategories();
      this.applyFilter("", true);
    } else {
      this.getFilterData(false);
      this.applyFilter("", false);
    }
    this.iscurrentFilterApplied = true;
  }
  unSelectAll(name) {
    this.currentFilter = name;

    this.productFilters[name].forEach((element) => {
      element.checked = false;
    });

    const ind = this.orderedFilterData.findIndex(f => f.key === name);
    this.orderedFilterData[ind].value.forEach(f => f.checked = false);

    this.filteredElements = this.filteredElements.filter((f) => f != name);
    this.page = 0;

    if (name === "class") {
      this.filterURL = '';
      this.getFilterData(true);
      this.getAvailableSubCategories();
      this.applyFilter("", true);
    } else {
      this.filterURL = '';
      this.getFilterData(false);
      this.applyFilter("", false);
    }
    let filterData = this.formFilterURL();

    this.filters = filterData;

    if (this.filters) {
      this.filterURL = JSON.parse(JSON.stringify(this.filters));
    }
    this.updateQueryString();
    this.iscurrentFilterApplied = true;
  }
  getFiltersData() {
    let filterUrl = "";

    delete this.productFilters["diameter"];
    delete this.productFilters["square"];

    for (var k in this.productFilters) {
      if (
        this.productFilters[k] &&
        isArray(this.productFilters[k]) &&
        (this.productFilters[k].length == 0 ||
          this.productFilters[k].every((e) => e.enabled == false))
      ) {
        delete this.productFilters[k];
      }
    }
    this.storedFilterData = JSON.parse(JSON.stringify(this.orderedFilterData));

    this.orderedFilterData = [];

    for (let i = 0; i < this.filterKeys.length; i++) {
      let openedData =this.storedFilterData.find(f=>f.key==this.filterKeys[i]) 
      if (this.productFilters[this.filterKeys[i]]) {
        this.orderedFilterData.push({
          key: this.filterKeys[i],
          value: this.productFilters[this.filterKeys[i]],
          opened: openedData&&  openedData.opened ?  true : false,
          showAll: openedData&&  openedData.showAll ? true :false,

        });
      }
      if (this.filterKeys[i] === 'class' ) {
        this.productFilters['subcategory'] = this.subCategories;
        let openedSubData =this.storedFilterData.find(f=>f.key=='subcategory') 
        this.orderedFilterData.push({
          key: 'subcategory',
          value: this.subCategories,
          opened: openedSubData&&  openedSubData.opened ?  true : false,
          showAll: openedSubData&&  openedSubData.showAll ? true :false,

        })
      }
    }
    if (this.storedFilterData && this.storedFilterData.length > 0) {
      this.orderedFilterData = this.api.compareAndUpdate(
        this.storedFilterData,
        this.orderedFilterData
      );
    }

    this.filterData = this.productFilters;
    this.originalFilterData = JSON.parse(JSON.stringify(this.filterData));

    this.sizeBackup = JSON.parse(JSON.stringify(this.productFilters["size"]));
    this.widthFilter = this.sizeBackup["width"];
    this.heightFilter = this.sizeBackup["height"];
    this.lengthFilter = this.sizeBackup["length"];
    this.depthFilter = this.sizeBackup["depth"];
    this.priceFilter = this.productFilters["price"];
    this.priceFilter["name"] = "price";

    this.filteredElements.forEach((f) => {
      if (
        !f.includes("price") &&
        f!==("size") &&

        !f.includes("diameter") &&
        !f.includes("width") &&
        !f.includes("height") &&
        !f.includes("length") &&
        !f.includes("depth")
      ) {
        let checked = [];
        if (this.filterData[f]) {
          this.filterData[f].forEach((element) => {
            if (element.checked) {
              checked.push(element.name);
            }
          });
          filterUrl = filterUrl + `${f}:${checked.join(",")};`;
        }
      } else if (f == "price") {
        filterUrl =
          filterUrl +
          `${"price_from"}:${this.price_from};${"price_to"}:${this.price_to};`;
      } else if (f == "diameter") {
        filterUrl =
          filterUrl +
          `${"diameter_from"}:${this.diameter_from};${"diameter_to"}:${this.diameter_to
          };`;
      } else if (f == "width") {
        filterUrl =
          filterUrl +
          `${"width_from"}:${this.width_from};${"width_to"}:${this.width_to};`;
      } else if (f == "height") {
        filterUrl =
          filterUrl +
          `${"height_from"}:${this.height_from};${"height_to"}:${this.height_to
          };`;
      } else if (f == "length") {
        filterUrl =
          filterUrl +
          `${"length_from"}:${this.length_from};${"length_to"}:${this.length_to
          };`;
      } else if (f == "depth") {
        filterUrl =
          filterUrl +
          `${"depth_from"}:${this.depth_from};${"depth_to"}:${this.depth_to};`;
      }
    });

    this.loadFilters = false;
    // this.urlFilter = filterUrl;
  }
  showAll=false;
   displayedItems(o): string[] {
    let value = o.value.filter(f=>f.enabled);
    return o.showAll ? value : value.slice(0, 12);
  }
enabledLength(o):number{
  let value = o.value.filter(f=>f.enabled);
  return value.length;
}
  toggleItems(o): void {
    o.showAll = !o.showAll;
  }


  getCheckedLength(name): boolean {
    return this.filterData[name].filter((f) => f.checked).length > 0;
  }
  getSelections(name): string {
    return this.filterData[name]
      .filter((f) => f.checked)
      .map((f) => f.name)
      .join(",");
  }
  clearSelections(name) {
    this.filterData[name].forEach((element) => {
      element.checked = false;
    });
    this.filteredElements = this.filteredElements.filter((f) => f != name);
    this.applyFilter("", false);
  }

  get getDeptName() {
    if (this.filterURL && this.filterURL.includes("class:")) {
      if (this.filterURL.split("class:")[1].includes(",")) {
        return " - Custom";
      } else if (this.filterURL.split("class:")[1] !== "" && this.filterData) {
        const name = this.filterData["class"].find(
          (f) => f.value === this.filterURL.split("class:")[1].split(";")[0]
        ).name;
        return " - " + name;
      }
    } else {
      return "";
    }
  }
  openNav() {
    document.getElementById("mySidenav").style.width = "650px";
    document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
    this.api.isSideNavOpened = true;
  }

  closeNav() {
    if (document.getElementById("mySidenav")) {
      document.getElementById("mySidenav").style.width = "0";
      document.body.style.backgroundColor = "white";
      this.api.isSideNavOpened = false;
    }
  }
  collapSection(o: any) {
    o.opened = !o.opened;
  }
  showClear(key) {
    if (this.filterData[key] && this.filterData[key].length > 0) {
      return this.filterData[key].some((f) => f.checked);
    } else {
      return false;
    }
  }

    showSizeClear(o){
      if(o.opened){
        return true;
      } else {
        return false;  }
    }
    clearSize(){
      this.iscurrentFilterApplied = false;

      this.sizeBackup = JSON.parse(
        JSON.stringify(this.originalFilterData["size"])
      );
      this.widthFilter = this.sizeBackup["width"];
      this.heightFilter = this.sizeBackup["height"];
      this.lengthFilter = this.sizeBackup["length"];
      this.depthFilter = this.sizeBackup["depth"]; 

      this.diameter_from = null;
      this.diameter_to = null;
      this.width_from = null;
      this.width_to = null;
      this.height_from = null;
      this.height_to = null;
      this.length_from = null;
      this.length_to = null;
      this.depth_from = null;
      this.depth_to = null; 
      this.filteredElements = [];
      this.filterURL = ''; 
      this.filterErr = '';
      this.noProducts = false; 
  
      let filterData = this.formFilterURL();
      this.productsLoading = true;
  
      this.filters = filterData;
      this.getFilterData(false);
      this.page = 0;
      this.getProductListing(0);
    }
  saveToProject() {
    let selectedProjects = this.productListings
      .filter((f) => f.checked)
      .map((f) => f.product_sku);
    let data = {
      room_id: this.selectedDropdown,
      product_id: selectedProjects.join(","),
    };
    this.api.addDesignCartProduct(data).subscribe((res:any) => {
      if(res.status){
        this.matSnackbar.open('Project saved successfully','x',{duration:3000});
        this.getProductListing();
      } else {
        this.matSnackbar.open(res.message,'x',{duration:5000});

      }
    });
  }
  get getChecks() {
    return this.productListings.filter((f) => f.checked).length > 0;
  }
  productInfo: any;
  isVariationCallLoading: boolean;
  isVariationExist: boolean;
  variations: any;
  hasSelection: any;
  newVariationFound: boolean;
  paramSku: string;
  loadDetails = false;

  getProductData(id) {
    this.closeProductNav();
    this.paramSku = id;
    this.loadDetails = true;
    this.openProductNav();
  }

  openProductNav() {
    document.getElementById("productSidenav").style.width = "450px";
    document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
    this.api.isSideNavOpened = true;
  }

  closeProductNav() {
    if (document.getElementById("productSidenav")) {
      this.loadDetails = false;
      document.getElementById("productSidenav").style.width = "0";
      document.body.style.backgroundColor = "white";
      this.api.isSideNavOpened = false;
    }
  }
}