import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/shared/services';

@Component({
    selector: 'app-image-comparison',
    styleUrls: ['./image-comparison.component.scss'],
    templateUrl: './image-comparison.component.html'
})
export class ImageComparisonComponent implements OnInit {
    private readonly FIRST_INDEX = 0;
    private readonly SECOND_INDEX = 1;

    @ViewChild('comparisonElement', {static: true}) private readonly comparisonElement;
    @Input() private readonly images: string[];
    @Input() private maxHeight: string | number = null;
    @Input() handSet : boolean;
    // @Input() designImages:any[]=[];
    selectedData: any;
    showSection = true;
    designImages: any[] = [
        // {
        //   title:'Mountain Lodge',
        //   image_before:'https://s3.us-east-2.amazonaws.com/lazysuzy-images/images/design/showcase/CL-before.jpg',
        //   image_after: 'https://s3.us-east-2.amazonaws.com/lazysuzy-images/images/design/showcase/CL-after.jpeg'
        // },
        // {
        //   title:'Suburban Dwelling',
        //   image_before:'https://s3.us-east-2.amazonaws.com/lazysuzy-images/images/design/showcase/IMG-20240308-WA0004.jpg',
        //   image_after: 'https://s3.us-east-2.amazonaws.com/lazysuzy-images/images/design/showcase/20240521_114636-pa.jpg'
        // },
      ];
      isBeforeImageLoaded = false;
      isAfterImageLoaded = false;
      hasBeforeImageError = false;
      hasAfterImageError = false
      

     constructor(private api:ApiService){}

    public ngOnInit(): void {

      this.api.getLandingDesignImages().subscribe((res:any)=>{
        this.designImages = res;
      //   // this.designImages.forEach(f=>{
      //   //   f.image_after = 'https://s3.us-east-2.amazonaws.com/lazysuzy-images'+f.image_after
      //   //   f.image_before = 'https://s3.us-east-2.amazonaws.com/lazysuzy-images'+f.image_before
 
      //   // })
      //   if (this.images.length != 2) {
      //     console.error(`Image comparison needs an array with two values (images URLs as string) and this is ${this.images.length} values.`);
      //     return;
      // }
      setTimeout(() => {
        
      this.initComparison();
      this.selectSection(this.designImages[0]);
      }, 100);
      })

    
    }
    onImageLoad(isBefore){
      if(isBefore){
        this.isBeforeImageLoaded = true;
      } else {
        this.isAfterImageLoaded = true;

      }
    }


    onImageError(isBefore) { 
      // if(isBefore){
      //   this.isBeforeImageLoaded = false;
      // this.hasBeforeImageError = true;

      // } else {
      //   this.isAfterImageLoaded = false;
      //   this.hasAfterImageError = true;

      // }
    }

    public getFirstImage(): string {
        return this.images[this.FIRST_INDEX];
    }

    public getSecondImage(): string {
        return this.images[this.SECOND_INDEX];
    }

    private initComparison(): void {

      const slider = document.querySelector(".image-comparison .slider");
      const beforeImage = document.querySelector(".image-comparison .before-image") as any;
      const sliderLine = document.querySelector(".image-comparison .slider-line") as any;
      const sliderIcon = document.querySelector(".image-comparison .slider-icon") as any;
      
      slider.addEventListener("input", (e:any) => {
        let sliderValue = e.target.value + "%";
      
        beforeImage.style.width = sliderValue;
        sliderLine.style.left = sliderValue;
        sliderIcon.style.left = sliderValue;
      });
      
    }
    selectSection(p){
      // this.isBeforeImageLoaded = false;
      // this.isAfterImageLoaded=false;

        this.showSection = false;
        this.selectedData = p;
        this.showSection = true;
    }
}