import { Injectable } from "@angular/core";
import { HttpService } from "../../shared/services/http/http.service";
import { forkJoin, Observable, of } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class OrderListApiService {

  baseUrl = environment.ADMIN_API_BASE_HREF;
  orderBaseUrl = environment.API_BASE_HREF;
  orderListEndpoint = "dashboard/orders";

  endUrl;
  orderId:string;
  orderDate:any;
  customer:any;

  constructor(private httpService: HttpService) {
    this.endUrl = this.baseUrl + this.orderListEndpoint;
  }

  getData(page, sort: string, status: string, watch: boolean): Observable<any> {
    const user = JSON.parse(localStorage.getItem("user"));
    let owner = "";
    if (user.authorization === 70) {
      owner = `&owner=${user.id}`;
    }
    if (status !== "") {
      return this.httpService.get(
        `${this.endUrl}?pageno=${page}&status=${status}${sort}${owner}&watch=${watch}`
      );
    } else {
      return this.httpService.get(
        `${this.endUrl}?pageno=${page}${sort}${owner}&watch=${watch}`
      );
    }
  }

  get(page): Observable<any> {
    const user = JSON.parse(localStorage.getItem("user"));
    let owner = "";
    if (user.authorization === 70) {
      owner = `&owner=${user.id}`;
    }
    return this.httpService.get(`${this.endUrl}?pageno=${page}${owner}`);
    // return this.httpService.get(`${this.endUrl}?pageno=${page}&status=09`);
  }

  update(data) {
    return this.httpService.post(this.endUrl, data);
  }
  // requestReview(data:any){
  //     const url = this.baseUrl + `dashboard/review_mailbvn`;
  //     return this.httpService.post(url, data);
  // }
  requestReview(order: string, productSku: any) {
    const requests: Observable<any>[] = [];
    Object.keys(productSku).forEach((type) => {
      const paramUrl = `dashboard/review_mail?order_id=${order}&skus=${productSku[
        type
      ].join(",")}`;
      // console.log(baseUrl + paramUrl);
      requests.push(this.httpService.get(this.baseUrl + paramUrl));
    });
    return forkJoin(requests);
  }

  sendEmailsExis(order_id, data: any[], tp: boolean) {
    const requests: Observable<any>[] = [];

    data.forEach((f) => {
      const baseUrl = `${environment.API_BASE_HREF}v1/mailer/trigger?order_id=${order_id}&skus=${f.sku}&type=${f.type}&trustpilot=${tp}&item=${f.item}&box=${f.box}`;
      requests.push(this.httpService.get(baseUrl));
    });
    return forkJoin(requests);
  }
  sendEmails(data: any) {
    const requests: Observable<any>[] = [];
    const url = `${environment.API_BASE_HREF}v1/mailer/trigger`;
    return this.httpService.post(url, data);
    // data.forEach(f => {
    //     const baseUrl = `${environment.API_BASE_HREF}v1/mailer/trigger?order_id=${order_id}&skus=${f.sku}&type=${f.type}&trustpilot=${tp}&item=${f.item}&box=${f.box}`;
    //     requests.push(this.httpService.get(baseUrl));
    // });
    // return forkJoin(requests);
  }
  sendEmailsOld(order: string, productSku: any) {
    const requests: Observable<any>[] = [];
    Object.keys(productSku).forEach((type) => {
      const baseUrl = environment.API_BASE_HREF + "v1/mailer/trigger?";
      // type=ORDER_DELIVERED&order_id=EWX3T&skus=335351
      const paramUrl = `type=${type.replace(
        / /g,
        "_"
      )}&order_id=${order}&skus=${productSku[type].join(",")}`;
      // console.log(baseUrl + paramUrl);
      requests.push(this.httpService.get(baseUrl + paramUrl));
    });
    return forkJoin(requests);
  }

  getStatusCodes() {
    const url = this.baseUrl + "dashboard/order_code";
    return this.httpService.get(url);
  }
  orderTracking(id: string, sku: string) {
    const url = this.orderBaseUrl + `order_tracking/${id}/${sku}`;
    return this.httpService.get(url);
  }
  trackOrder(id: string) {
    const url = this.baseUrl + `dashboard/ordertracking/${id}`;
    return this.httpService.get(url);
  }
  getProductPO(id: string) {
    const url = this.baseUrl + `dashboard/get_product_po/${id}`;
    return this.httpService.get(url);
  }
  getShipType() {
    const url = this.baseUrl + `dashboard/ship_type`;
    return this.httpService.get(url);
  }
  getOrderCostData(order_id: string) {
    const url = environment.API_BASE_HREF + `get_order_cos/${order_id}`;
    return this.httpService.get(url);
  }
  saveOrderCostData(data: any) {
    const url = environment.API_BASE_HREF + `save_orderCosData`;
    return this.httpService.post(url, data);
  }

  getCarrierList() {
    const url = this.orderBaseUrl + `get_order_courier`;
    return this.httpService.get(url);
  }
  getReturnCodesList() {
    const url = this.baseUrl + "dashboard/return_code";
    return this.httpService.get(url);
  }
  getCancelReasonsList() {
    const url = this.baseUrl + "dashboard/cancel_code";
    return this.httpService.get(url);
  }
  saveOrderTracking(data: any) {
    const url = this.orderBaseUrl + `save_orderTracking`;
    return this.httpService.post(url, data);
  }

  addOrder(data: any) {
    const url = this.baseUrl + `dashboard/order_item_add`;
    return this.httpService.post(url, data);
  }
  deleteOrderBox(data: any[]) {
    const url = this.baseUrl + `dashboard/remove_trackingitem`;
    return this.httpService.post(url, data);
  }
  getOrderSummary(id: string) {
    const url = this.baseUrl + `dashboard/get_order_delivery/${id}`;
    return this.httpService.get(url);
  }
  saveAddress(data: any) {
    const url = this.baseUrl + `dashboard/save_ordersummary`;
    return this.httpService.post(url, data);
  }
  searchOrders(data: any) {
    // const user = JSON.parse(localStorage.getItem("user"));
    // if (user.authorization === 70) {
    //   data.owner= user.id;
    // }
    const url = this.baseUrl + `dashboard/search_order`;
    return this.httpService.post(url, data);
  }
  getTemplateList() {
    const url = environment.API_BASE_HREF + `get_email_template`;
    return this.httpService.get(url);
  }
  scheduleReview(data: any) {
    const url = this.baseUrl + `dashboard/email_tracker_review`;
    return this.httpService.post(url, data);
  }
  getScheduleReview(order_id: any) {
    const url = environment.API_BASE_HREF + `get_review_data/${order_id}`;
    return this.httpService.get(url);
  }
  quickOrder(data) {
    const url = this.orderBaseUrl + `admin_order`;
    return this.httpService.post(url, data);
  }
  toggleWatch(data: any) {
    const url = this.orderBaseUrl + `save_watch`;
    return this.httpService.post(url, data);
  }
  getUserList() {
    const url = environment.API_BASE_HREF + `user_list?authorization=70,90`;
    return this.httpService.get(url);
  }
  addPickup(data: any) {
    const url = this.orderBaseUrl + `save_pickup`;
    return this.httpService.post(url, data);
  }
}
